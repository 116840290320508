import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "./app/store"

export interface WallState {
  colors: string[]
}

const initialState: WallState = {
  colors: [
    "#6a3d8a",
    "#3b82f6",
    "#b55ba7",
    "#22c55e",
    "#6a3d8a",
    "#3b82f6",
    "#d9e3f0",
    "#d9e3f0",
  ],
}
export const wallSlice = createSlice({
  name: "Wall",
  initialState,
  reducers: {
    changeColors: (state, action: PayloadAction<string[]>) => {
      state.colors = action.payload.map((x) => x)
    },
  },
  // function setColorAtIndex(color: string, index: number) {
  //   setBlockPickerColors(
  //     blockPickerColors.map((c, i) => {
  //       if (i === index) {
  //         // Increment the clicked counter
  //         return color
  //       } else {
  //         return c
  //       }
  //     }),
  //   )
  // }
})
export const selectColors = (state: RootState) => {
  return state.wall.colors
}
export const { changeColors } = wallSlice.actions

export default wallSlice.reducer
