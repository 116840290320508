import { store } from "./app/store"

import { initializeApp } from "firebase/app"
//import { getAuth, signInAnonymously } from "firebase/auth"
import {
  Firestore,
  doc,
  getFirestore,
  initializeFirestore,
  memoryLocalCache,
  onSnapshot,
  setDoc,
} from "firebase/firestore"

import { changeColors } from "./wallSlice"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBp3oXcvHGekyVBvSpJhiVMzG6CcC1yC9s",
  authDomain: "lara-s-wall.firebaseapp.com",
  projectId: "lara-s-wall",
  storageBucket: "lara-s-wall.appspot.com",
  messagingSenderId: "309915388060",
  appId: "1:309915388060:web:fd158ef8dec740b3868559",
  measurementId: "G-NXG5ZRPZPL",
}
let db: Firestore
export function updateColor(color: string, index: number) {
  // Add a new document in collection "cities"
  const localColors = store.getState().wall.colors.map((c, i) => {
    if (i === index) {
      // Increment the clicked counter
      return color
    } else {
      return c
    }
  })
  setDoc(doc(db, "wall colors", "peBHC6lYHFD5nUWvdIFw"), {colors: localColors})
}
export function initWall() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig)

  let loadedData = localStorage.getItem("colors")
  if (loadedData === null) {
    store.dispatch(changeColors([]))
  } else {
    let colorData = JSON.parse(loadedData)
    store.dispatch(changeColors(colorData))
  }
  // const auth = getAuth()
  // signInAnonymously(auth)
  //   .then(() => {
  //     // Signed in..
  //   })
  //   .catch((error) => {
  //     // ...
  //   })

  console.log("after")
  initializeFirestore(app, { localCache: memoryLocalCache() })
  // Initialize Cloud Firestore and get a reference to the service
  db = getFirestore(app)

  onSnapshot(doc(db, "wall colors", "peBHC6lYHFD5nUWvdIFw"), (doc) => {
    store.dispatch(changeColors(doc.data()?.colors))
    localStorage.setItem("colors", JSON.stringify(doc.data()?.colors))
    const source = doc.metadata.hasPendingWrites ? "Local" : "Server"
    console.log(source, " data: ", doc.data()?.colors)
  })
}
